@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}
html {
    scroll-behavior: smooth;
}
body {
    min-height: 1000px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 100px;
    z-index: 1000;
    transition: 0.6s;
}
header.sticky {
    padding: 5px 100px;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
header .logo {
    position: relative;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    font-size: 2em;
    letter-spacing: 2px;
    transition: 0.6s;
}
header ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
header ul li {
    position: relative;
    list-style: none;
}
header ul li a {
    position: relative;
    margin: 0 15px;
    text-decoration: none;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 300;
    transition: 0.6s;
}
header.sticky .logo,
header.sticky ul li a {
    color: #000;
}
.scrollbar-custom::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
}

.bannerForComingSoon {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url(../../public/img/ComingSoon.jpg);
    background-size: cover;
    display: flex;
    align-items: center;
}

.glowing-btn {
    position: relative;
    color: whitesmoke;
    cursor: pointer;
    padding: 0.35em 1em;
    border: 0.15em solid var(--glow-color);
    border-radius: 0.45em;
    background: none;
    perspective: 2em;
    font-family: "Raleway", sans-serif;
    font-size: 2em;
    font-weight: 900;
    letter-spacing: 5px;

    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
    animation: border-flicker 2s linear infinite;
}

.glowing-txt {
    float: left;
    margin-right: -0.8em;
    -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
    -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
    animation: text-flicker 3s linear infinite;
}

.faulty-letter {
    opacity: 0.5;
    animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    filter: blur(1em);
    transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
    background: var(--glow-color);
    pointer-events: none;
}

.glowing-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    background-color: var(--glow-color);
    box-shadow: 0 0 2em 0.2em var(--glow-color);
    transition: opacity 100ms linear;
}

.glowing-btn:hover {
    color: rgba(255, 255, 255, 0.8);
    text-shadow: none;
    animation: none;
}

.glowing-btn:hover .glowing-txt {
    animation: none;
}

.glowing-btn:hover .faulty-letter {
    animation: none;
    text-shadow: none;
    opacity: 1;
}

.glowing-btn:hover:before {
    filter: blur(1.5em);
    opacity: 1;
}

.glowing-btn:hover:after {
    opacity: 1;
}

@keyframes faulty-flicker {
    0% {
        opacity: 0.1;
    }
    2% {
        opacity: 0.1;
    }
    4% {
        opacity: 0.5;
    }
    19% {
        opacity: 0.5;
    }
    21% {
        opacity: 0.1;
    }
    23% {
        opacity: 1;
    }
    80% {
        opacity: 0.5;
    }
    83% {
        opacity: 0.4;
    }

    87% {
        opacity: 1;
    }
}

@keyframes text-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 1;
    }

    8% {
        opacity: 0.1;
    }

    9% {
        opacity: 1;
    }

    12% {
        opacity: 0.1;
    }
    20% {
        opacity: 1;
    }
    25% {
        opacity: 0.3;
    }
    30% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }
    72% {
        opacity: 0.2;
    }

    77% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.9;
    }
}

@keyframes border-flicker {
    0% {
        opacity: 0.1;
    }
    2% {
        opacity: 1;
    }
    4% {
        opacity: 0.1;
    }

    8% {
        opacity: 1;
    }
    70% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .glowing-btn{
        font-size: 1em;
    }
}


.bannerForComingSoon h2 {
    color: white;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin-right: auto;
}

/*.bannerForComingSoon button:hover {*/
/*    background-color: #3e8e41;*/
/*}*/

.banner {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url(../../public/img/electroWallpaper.jpeg);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner h2 {
    margin-bottom: 15%;
    color: #fff;
    font-size: 70px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1em;
}
.banner h2 span {
    color: #4978ff;
}

/*About*/

.sec {
    background-color: #fff;
    padding: 100px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec .content {
    position: relative;
    text-align: center;
    width: 100%;
}
.mxw800p {
    max-width: 800px;
    margin: 0 auto;
}
h3 {
    font-size: 40px;
    font-weight: 200;
    margin-bottom: 10px;
    color: #000;
}
.mxw800p p {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    letter-spacing: 1px;
    color: #000;
}
.btn {
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    background: #000;
    color: #fff;
    text-decoration: none;
}

/* services */
.services {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
    max-width: 100%;
    margin-top: 40px;
}
.services .box {
    width: 300px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
}
.services .box .iconBx {
    margin-top: 25px;
}
.services .box .iconBx img {
    max-width: 70px;
    margin-bottom: 15px;
}
.services .box h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

/* stats */

.stats {
    background: #000;
    padding-top: 250px;
    margin-top: -250px;
}
.stats h3,
.stats p {
    color: #fff;
}

.statsBox {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    max-width: 100%;
    margin-top: 40px;
}
.statsBox h2 {
    color: #fff;
    font-size: 36px;
}

.statsBox h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/*Portfolio*/

.workBx {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    max-width: 100%;
    margin-top: 40px;
}
.workBx .brand {
    position: relative;
    background-color: #4978ff;
    min-height: 300px;
    width: 250px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.workBx .brand:hover {
    background: #000;
}
.workBx .brand a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
}
.workBx .brand :hover a {
    color: rgba(255, 255, 255, 1);
}

/*Team*/

.teamBx {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    max-width: 100%;
    margin-top: 40px;
}
.teamBx .member {
    position: relative;
    background-color: #000;
    min-height: 300px;
    width: 250px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.teamBx .member .imgBx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.teamBx .member .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.teamBx .member .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: 0.2s;
    opacity: 0;
}
.teamBx .member:hover .details {
    opacity: 1;
}
.teamBx .member .details h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
}
.teamBx .member .details h2 span {
    display: block;
    font-size: 12px;
    font-weight: 300;
}

/*Contact*/

.contact {
    background-color: #000;
}
.contact h3,
.contact p {
    color: #fff;
}
.contactForm {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
}
.contactForm form {
    width: 100%;
}
.contactForm .row100 {
    display: flex;
    width: 100%;
}
.contactForm .row100 .inputBx50 {
    width: 50%;
    margin: 0 20px;
}
.contactForm .row100 .inputBx100 {
    width: 100%;
    margin: 0 20px;
}
.contactForm .row100 input,
.contactForm .row100 textarea {
    position: relative;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background: transparent;
    width: 100%;
    padding: 10px 0;
    outline: none;
    font-size: 18px;
    font-weight: 300;
    margin: 20px 0;
    resize: none;
}
.contactForm .row100 textarea {
    height: 100px;
}
.contactForm .row100 input::placerholder,
.contactForm .row100 textarea::placerholder {
    color: rgba(255, 255, 255, 0.5);
}
.contactForm .row100 input[type="submit"] {
    background: #fff;
    color: #000;
    max-width: 120px;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    font-weight: 500;
}
.sci {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}
.sci ul {
    display: flex;
}
.sci ul li {
    list-style: none;
}
.sci ul li a {
    text-decoration: none;
    display: inline-block;
    margin: 0 30px;
}
.sci ul li a img {
    filter: invert(1);
    max-width: 40px;
}
.sci ul li a:hover img {
    opacity: 0.5;
}
.copyright a {
    color: #fff;
}
